import getHtmlParam from '../../utilities/htmlParam';
import { fadeIn, fadeOut } from '../../utilities/helpers';
import 'element-closest-polyfill';

declare var productArticles: any;
const mp = getHtmlParam('.js-mp .param');
const param = getHtmlParam('.js-param .param');
let aId: string = '';
let pId: string = '';

export function updateWishlists(context?: string, row?: HTMLElement, article?: string) {
  (document.querySelector('.modal-product-response') as HTMLElement).style.display = 'none';

  window.fetch('/user/my-shop/wishlist')
    .then(res => res.json())
    .then((wishlists) => {
      let articleTitle:string;
      let articleId: any;
      let productId: any;
      let cartItemId: any;
      let articlePrice: any;
      let articleImageSource = '';
      if (context === 'product-table' && row) {
        articleTitle = row.dataset.ptitle;
        articleId = row.dataset.aid;
        productId = row.dataset.pid;
        articlePrice = row.querySelector('.variant-price').innerHTML;
      } else if (context === 'hotspotwidget' && row) {
        articleTitle = (<HTMLElement>row.parentElement.parentElement.parentElement.querySelector('a span')).innerText;
        articleId = row.dataset.aid;
        productId = row.dataset.pid;
        articlePrice = row.parentElement.parentElement.querySelector('.product-item-price-wrapper').innerHTML;
      } else if (context === 'cart' && row) {
        articleTitle = row.closest('p').querySelector('.item-description > *').innerHTML;
        articleId = (<HTMLElement>row.closest('.divTableRow')).dataset.orderCartArticleId;
        productId = (<HTMLElement>row.closest('.divTableRow')).dataset.orderItemId;
        cartItemId = (<HTMLElement>row.closest('.divTableRow')).dataset.orderCartItemId;
        articlePrice = row.closest('.divTableRow').querySelector('.price-cartitem-' + cartItemId + '-articlePrice').innerHTML;
        articlePrice = '<div class="prc-wrapper"><div class="prc-article prc-article-nobest-saving"><div class="price-article"><div class="prc-offer"><span class="price-detail-offer ">' + articlePrice + '</span></div></div></div></div>';
        const articleImage = row.closest('.divTableRow').querySelector('.basket-item-image img');
        articleImage && (articleImageSource = articleImage.parentElement.innerHTML);
      } else if (context === 'pues' && row && article) {
        articleTitle = row.querySelector('.js-logging-link-title').innerHTML;
        articleId = article;
        articlePrice = row.querySelector('.product-item-price-wrapper');
        articlePrice && (articlePrice = articlePrice.innerHTML);
        const articleImage = row.querySelector('.js-logging-link-image img') as HTMLImageElement;
        articleImage && (articleImageSource = '<img src="' + articleImage.src + '" />');
      } else if (context === 'compare' && row) {
        articleId = row.dataset.articleId;
        articleId && (articleTitle = document.querySelector('.article-title-' + articleId).innerHTML);
        articleId && (articlePrice = document.querySelector('.article-price-' + articleId + ' .results-bestprice'));
        articlePrice = articlePrice ? articlePrice.innerHTML : '';
        const articleImage: HTMLImageElement = document.querySelector('.article-image-' + articleId);
        articleImage && (articleImageSource = '<img src="' + articleImage.src + '" />');
      } else {
        articleTitle = document.getElementById('article-title').innerHTML;
        articleId = document.getElementById('article-id').getAttribute('data-article-id');
        productId = param.get('productId');
        articlePrice = document.getElementById('article-price').innerHTML;
        const articleImage = document.querySelector('#pda-gallery-hero [data-article-id="' + articleId + '"] picture');
        articleImage && (articleImageSource = articleImage.innerHTML);
      }

      const titles: Array<HTMLElement> = Array.from(document.querySelectorAll('.modal-product-title'));
      titles.forEach(el => el.innerHTML = articleTitle);
      const ids: Array<HTMLElement> = Array.from(document.querySelectorAll('#modal-wishlist .modal-article-id'));
      ids.forEach(el => el.innerHTML = articleId);
      if (productId) {
        const pids: Array<HTMLElement> = Array.from(document.querySelectorAll('#modal-wishlist .modal-product-id'));
        pids.forEach(el => el.innerHTML = productId);
      }
      const prices: Array<HTMLElement> = Array.from(document.querySelectorAll('.modal-product-price'));
      prices.forEach(el => el.innerHTML = articlePrice);
      const images: Array<HTMLElement> = Array.from(document.querySelectorAll('.modal-product-image'));
      articleImageSource && images.forEach(el => el.innerHTML = articleImageSource);

      const wishlistField = document.getElementById('wishlists');
      if (wishlistField) {
        wishlistField.innerHTML = '';

        if (wishlists.length > 0) {
            wishlists.forEach((wishlist: any) => {
              wishlistField.innerHTML += '<option value="' + wishlist.publicId + '">' + wishlist.name + '</option>';
            });
        }

        wishlistField.innerHTML += '<option value="new">' + mp.get('wishlist_new') + '</option>';
      }

    }).catch(err => {
      console.log(err);
    });
}

export function addToWishlist(context?: string, articleId?: string, productId?: string, callback?: Function): void {

  let article;
  let product;
  if (context === 'cart' || context === 'pues' || context === 'hotspotwidget') {
    article = articleId;
    product = productId;
  } else {
    const pda = document.querySelector('.pda');
    if (pda) {
      article = pda.getAttribute('data-article-id');
      product = param.get('productId');
    }
  }

  const wishlists = (document.getElementById('wishlists') as HTMLSelectElement);
  const wishlistId = wishlists.options[wishlists.selectedIndex].value;

  const articleData = 'articleId=' + article + '&productId=' + product;

  if (wishlistId === 'new') {
    window.fetch(param.get('urlPrefix') + '/user/my-shop/wishlist/', {
        method: 'POST',
      })
      .then(res => res.text())
      .then((data) => {
        if (data.length > 0) {
          window.fetch(param.get('urlPrefix') + '/user/my-shop/wishlist/' + data, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: articleData,
          })
          .then(response => response)
          .then(data => {
            (document.querySelector('.modal-product-response') as HTMLElement).style.display = 'block';
            // updateWishlists(context);
            toggleWishlist(false, productId, articleId);
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }
      }).catch(err => {
        console.log(err);
      });
  } else {
    window.fetch(param.get('urlPrefix') + '/user/my-shop/wishlist/' + wishlistId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: articleData,
      })
      .then(response => response)
      .then(data => {
        (document.querySelector('.modal-product-response') as HTMLElement).style.display = 'block';
        // updateWishlists(context);
        toggleWishlist(false, productId, articleId);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
}

export function removeFromWishlist(articleId?: string, productId?: string) {
  let article;
  let product;
  if (articleId) {
    article = articleId;
    product = productId;
  } else {
    article = document.querySelector('.pda').getAttribute('data-article-id');
    product = param.get('productId');
  }
  const articleData = 'articleId=' + article + '&productId=' + product;

  window.fetch(param.get('urlPrefix') + '/user/my-shop/wishlist/delete-item', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
      body: articleData,
    })
    .then(response => response)
    .then(data => {
      toggleWishlist(true, productId, articleId);
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

export function onClickWishlistonPues(target: Element) {

  const url = new URL(window.location.href);
  const productCta = target.closest('.wishlist-cta');
  let article: HTMLElement;
  if (productCta) {
    article = productCta.closest('.product-element') as HTMLElement;
    !article && (article = productCta.closest('.pues-list-item') as HTMLElement);
    event.preventDefault();
    article && (aId = article.dataset.articleId);
    article && (pId = article.dataset.productId);
    if (productCta.classList.contains('remove-from-wishlist')) {
      removeFromWishlist(aId, pId);
      return false;
    }
    if (param.get('puesType')) {
      openWishlistModal('pues', pId, aId);
    } else {
      openWishlistModal();
    }
    const modalClose = document.querySelectorAll('.modal-close, .modal-background');
    modalClose.forEach((modal) => {
      modal.addEventListener('click', () => {
        url.searchParams.delete('wishlist');
        window.history.pushState({}, '', url.toString());
      });
    })
  }
}

export function initWishlist() {
  const url = new URL(window.location.href);
  const addToWishlistBtn = document.querySelector('.add-to-wishlist');
  let articleId: string = '';
  let productId: string = '';

  if (window.location.search.indexOf('wishlist=1') !== -1) {
    if (window.location.search.indexOf('wishitem') !== -1 && param.get('puesType')) {
      const Ids = url.searchParams.get('wishitem').split(',');
      openWishlistModal('pues', Ids[0], Ids[1]);
      pId = Ids[0];
      aId = Ids[1];
    } else {
      openWishlistModal();
    }
    url.searchParams.delete('wishlist');
    url.searchParams.delete('wishitem');
    window.history.pushState({}, '', url.toString());
  }

  addToWishlistBtn && addToWishlistBtn.addEventListener('click', () => {
    event.preventDefault();
    if (param.get('puesType')) {
      if (aId && pId) {
        addToWishlist('pues', aId, pId);
      } else {
        addToWishlist('pues', document.querySelector('.modal-article-id').textContent, document.querySelector('.modal-product-id').textContent);
      }
    }
    if (document.querySelector('.page-pda')) {
      addToWishlist();
    }
  });
}

function openWishlistModal(context?: string, productId?: string, articleId?: string) {
  updateWishlists(context, document.getElementById('product-' + productId), articleId);
  const url = new URL(window.location.href);
  url.searchParams.set('wishlist', '1');
  productId && url.searchParams.set('wishitem', productId + ',' + articleId);
  window.history.pushState({}, '', url.toString());
  fadeIn(document.querySelector('.modal-background'));
  fadeIn(document.querySelector('#modal-wishlist'));
}

function toggleWishlist(active: boolean, productId: string, articleId?: string) {
  let element = document.getElementById('product-' + productId);
  !element && (element = document.querySelector('.pda-entry-content'));
  const compareElement = document.querySelectorAll('.article-control-' + articleId);
  if (compareElement[0]) {
    compareElement.forEach((el: HTMLElement) => {
      const btn: HTMLElement = el.querySelector('.article-to-wishlist');
      btn.innerHTML = btn.dataset.saved;
      btn.style.pointerEvents = 'none';
      btn.style.color = '#037927';
    });
    return false;
  }

  if (element) {
    element.querySelectorAll('.wishlist-cta').forEach((cta: HTMLElement) => (cta.style.display = 'none'));
    if (active) {
      if(element.querySelector('.toggle-wishlist')) {
        (element.querySelector('.toggle-wishlist') as HTMLElement).style.display = 'inline-flex';
      }
    } else {
      if(element.querySelector('.remove-from-wishlist')) {
        (element.querySelector('.remove-from-wishlist') as HTMLElement).style.display = 'inline-flex';
      }
    }
  }
}
