import { windowLoaded } from "../../utilities/domready";
import {fadeIn, fadeOut} from "../../utilities/helpers";

export class Energylabel {

    energylabelOnClick() {
        windowLoaded(() => {
            document.addEventListener('click', (e) => {
                const target = e.target as HTMLElement;
                if (!target) return;
                const targetParent = target.parentElement;
                if(target.classList.contains('energylabel-open') || targetParent && targetParent.classList.contains('energylabel-open')) {
                    e.preventDefault();

                    let el:HTMLElement;

                    if((e.target as HTMLElement).classList.contains('energylabel-open')) {
                        el = (e.target as HTMLElement);
                    } else {
                        el = (e.target as HTMLElement).parentElement;
                    }

                    if(document.querySelector('#modal-energylabel')) {
                        const imgsrc = el.getAttribute('data-imgsrc');
                        const pdfsrc = el.getAttribute('data-pdfsrc');
                        const pdflink:HTMLAnchorElement = document.querySelector('#modal-energylabel .energylabel-pdf');
                        const energylabelImg:HTMLElement = document.querySelector('#modal-energylabel .energylabel-img');

                        if(pdfsrc) {
                            pdflink.style.display = 'inline-block';
                            pdflink.href = pdfsrc;
                        } else {
                            pdflink.style.display = 'none';
                        }
                        if(imgsrc) {
                            energylabelImg.innerHTML = '<img src="'+imgsrc+'">';
                            energylabelImg.style.display = 'block';
                        } else {
                            energylabelImg.innerHTML = '';
                            energylabelImg.style.display = 'none';
                        }

                        fadeIn(document.querySelector('#modal-energylabel'));
                        fadeIn(document.querySelector('.modal-background'));
                    }
                }
                if (target.classList.contains('energylabel-pdf-nolink')) {
                    Object.assign(document.createElement('a'), {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        href: (e.target as HTMLElement).getAttribute('data-href'),
                    }).click();
                }
            });
        });
    }

    init() {
        this.energylabelOnClick();
    }
}