export function domReady(callBack: () => void) {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callBack);
    }
    else {
        callBack();
    }
}

export function windowLoaded(callBack: () => void) {
    if (document.readyState === 'complete') {
        callBack();
    }
    else {
        window.addEventListener('load', callBack);
    }
}