import * as traversal from '../../utilities/traversal';
import {fadeIn, fadeOut} from '../../utilities/helpers';
import {forkJoin, fromEvent, tap, timer} from "rxjs";
import getHtmlParam from '../../utilities/htmlParam';
import {getReminder, setReminder, removeReminder, setupTooltip} from "../product/reminder";



let param = getHtmlParam('.js-param .param');

export class headTools {

    protected desktopWidth: number = 1280;
    protected minDesktopWidthQuery = window.matchMedia(`(min-width: ${this.desktopWidth}px)`);
    protected tabletWidth: number = 532;
    protected defaultSuggestContainer: HTMLElement = document.getElementById('js-default-suggest-container');
    protected suggestionBackground: HTMLElement = document.querySelector('.suggestion-background');
    protected suggestContainer: HTMLElement = document.getElementById('js-head-search-suggestion')
    //protected activeTrial: string = (<HTMLInputElement>document.getElementById('activetrial'))?.value;
    protected maxTabletLandscapeWidth: number = 1279;
    protected maxTabletLandscapeWidthQuery = window.matchMedia(`(max-width: ${this.maxTabletLandscapeWidth}px)`);
    protected body = document.getElementsByTagName('body')[0];
    protected headWrapper: HTMLDivElement = document.querySelector('.head-wrapper');

    toggleExtendedView() {
        const headWrapper = document.querySelectorAll('.head-wrapper')[0];
        const headStickyToggle = document.querySelectorAll('.head-sticky-toggle')[0];

        if (typeof headWrapper !== 'undefined') {
            headStickyToggle?.addEventListener('click', () => {
                if (headWrapper.classList.contains('_active')) {
                    headWrapper.classList.remove('_active');
                } else {
                    headWrapper.classList.add('_active');
                }
            });
        }
    }

    closeFlyout() {
        const html = document.getElementsByTagName('html')[0];
        let closeToggle: NodeList = document.querySelectorAll('.toggle-flyout-close');


        if (typeof closeToggle !== 'undefined') {
            closeToggle = [].slice.call(closeToggle);

            closeToggle.forEach((item: HTMLElement) => {
                item.addEventListener('click', () => {
                    // add _active class to current subnav
                    let flyout = traversal.parents(item, '.head-tool-flyout');
                    flyout[0].setAttribute('style', 'visibility: hidden; opacity: 0;');
                    html.classList.remove('_active-head-nav');
                });
            });
        }
    }

    toggleFlyout() {
        const html = document.getElementsByTagName('html')[0];
        let flyoutToggle: NodeList = document.querySelectorAll('.head-tool');
        const maxTablet = window.matchMedia('(max-width: 991px)');

        const flyoutItemCount = document.querySelector('.flyout-cart-item-count');
        if (flyoutItemCount) {
            const headCartItemCount = document.querySelector('.js-head-cart-itemcount');
            headCartItemCount && (headCartItemCount.innerHTML = flyoutItemCount.textContent);
        }

        if (typeof flyoutToggle !== 'undefined') {
            flyoutToggle = [].slice.call(flyoutToggle);

            flyoutToggle.forEach((item: HTMLElement) => {
                if (!item.classList.contains('head-languages') && !item.classList.contains('head-profiles')) {
                    item.addEventListener('mouseover', () => {

                        this.closeAllFlyouts(document.querySelectorAll('.head-tool')); // close all flyouts before showing current flyout

                        // add _active class to current subnav
                        let flyout = item.querySelectorAll('.head-tool-flyout')[0];
                        flyout.setAttribute('style', 'visibility: visible; opacity: 1;');
                        if (maxTablet.matches) {
                            html.classList.add('_active-head-nav');
                        }
                    });
                    item.addEventListener('mouseout', () => {
                        let inputUser: HTMLElement = item.getElementsByTagName('input')[0];
                        let inputPass: HTMLElement = item.getElementsByTagName('input')[1];

                        if (inputUser === document.activeElement || inputPass === document.activeElement) {

                        } else {
                            // add _active class to current subnav
                            let flyout = item.querySelectorAll('.head-tool-flyout')[0];
                            flyout.setAttribute('style', 'visibility: hidden; opacity: 0;');
                            if (maxTablet.matches) {
                                html.classList.remove('_active-head-nav');
                            }
                        }
                    });
                }
            });
        }
    }

    preventDefaultCart() {
        const maxTablet = window.matchMedia('(max-width: 991px)');
        let headCartLink = document.querySelectorAll('.head-cart-link')[0];
        if (typeof headCartLink !== 'undefined') {
            headCartLink.addEventListener('click', (e) => {
                if (maxTablet.matches) {
                    e.preventDefault();
                }
            });
        }
    }

    closeSuggestCategoryProductAndSearchHistory(searchBar: HTMLElement) {
        this.suggestContainer.style.display = 'none';
        this.suggestContainer.classList.remove('active');
        this.closeDefaultSuggest();
        fadeOut(this.suggestionBackground, 'flex');
        if (this.maxTabletLandscapeWidthQuery.matches) {
            this.stickyCheck();
            this.mobileSearchBarCategoryProductAndSearchHistory(searchBar);
        }
    }

    closeDefaultSuggest() {
        this.defaultSuggestContainer && (this.defaultSuggestContainer.style.display = 'none');
        fadeOut(this.suggestionBackground);
    }

    productSearch() {
        const queryString = document.getElementById('searchQueryString') as HTMLInputElement;
        const getSearchParams = window.location.search;
        const searchForm = document.getElementById('form-header-search') as HTMLFormElement;
        searchForm && searchForm.addEventListener('submit', () => {
            const searchInput = searchForm.querySelector('#header-search-query') as HTMLInputElement;
            if (searchInput.value.trim().length === 0) {
                event.preventDefault();
            }
        });
        if (queryString && queryString.value) {
            (document.getElementById('header-search-query') as HTMLInputElement).value = queryString.value;
        } else if (getSearchParams) {
            const urlParams = new URLSearchParams(getSearchParams);
            const searchQuery = urlParams.get('originalSearchTerm');
            searchQuery && ((document.getElementById('header-search-query') as HTMLInputElement).value = searchQuery);
        }
    }

    searchResetButtonProductCategoryHistory(searchBar: HTMLElement) {
        if (document.querySelector('.head-search-wrapper-c__head-search-form__searchfield__cancel-search-wrapper')
            && document.querySelector('.head-search-wrapper-c__head-search-form__searchfield input[type=text]')) {

            const cancelButton = (<HTMLElement>document.querySelector('.head-search-wrapper-c__head-search-form__searchfield__cancel-search-wrapper'));
            const input = (<HTMLInputElement>document.querySelector('.head-search-wrapper-c__head-search-form__searchfield input[type=text]'));

            input.addEventListener('keyup', () => {
                if (input.value !== '') {
                    cancelButton.style.display = 'flex';
                } else {
                    cancelButton.style.display = 'none';
                }
            });
            input.addEventListener('focusout', () => {
                cancelButton.classList.remove('focused');
                if (input.value !== '') {
                    cancelButton.style.display = 'flex';
                } else {
                    cancelButton.style.display = 'none';
                }
            });
            input.addEventListener('focusin', () => {
                cancelButton.classList.add('focused');
                if (input.value !== '') {
                    cancelButton.style.display = 'flex';
                } else {
                    cancelButton.style.display = 'none';
                }
            });
            cancelButton.addEventListener('mousedown', (e) => {
                input.value = '';
                cancelButton.style.display = 'none';
                input.focus();
                if (document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper')) {

                    const backBtnWrapper: HTMLDivElement = document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper');
                    backBtnWrapper.style.width = '0px';
                    backBtnWrapper.style.display = 'none';
                }
                this.closeSuggestCategoryProductAndSearchHistory(searchBar);
            })
            cancelButton.addEventListener('click', (e) => {
                input.value = '';
                cancelButton.style.display = 'none';
                input.focus();
                if (document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper')) {

                    const backBtnWrapper: HTMLDivElement = document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper');
                    backBtnWrapper.style.width = '0px';
                    backBtnWrapper.style.display = 'none';
                }
                this.closeSuggestCategoryProductAndSearchHistory(searchBar);
            })
        }

        if (document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper')
            && document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper__back-btn')
            && document.querySelector('.head-search-wrapper-c__head-search-form__searchfield input[type=text]')) {

            const backBtnWrapper: HTMLDivElement = document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper')
            const backBtn: HTMLDivElement = document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper__back-btn')

            backBtn.addEventListener('click', (e) => {
                backBtnWrapper.style.width = '0px'
                backBtnWrapper.style.display = 'none'
                this.closeSuggestCategoryProductAndSearchHistory(searchBar);
            })
        }
    }

    topBar() {
        const topBar = document.getElementById('header-topbar');

        if (!topBar) return;

        const closeBar = document.getElementById('top-bar-close');
        if (closeBar) {
            closeBar.addEventListener('click', (event) => {
                event.preventDefault();
                topBar.parentNode.removeChild(topBar);
                setCookie();
            });
        }

        const copyAnkers = document.querySelectorAll('.cta-ad-message-es, .promo-code');

        if (copyAnkers) {
            copyAnkers.forEach((anker) => {
                anker.addEventListener('click', () => copyClipboard());
            });
        }

        const setCookie = () => {
            const now = new Date();
            const time = now.getTime();
            const expireTime = time + 1000 * 24000;
            now.setTime(expireTime);
            document.cookie = 'ad_seen=true;expires=' + now.toUTCString() + ';path=/;secure;';
        }

        const copyClipboard = () => {
            const promoCode = document.getElementById('promo-code');

            if (promoCode) {
                if ((document.body as any).createTextRange) {
                    // for Internet Explorer
                    const range = (document.body as any).createTextRange();
                    range.moveToElementText(promoCode);
                    range.select();
                    document.execCommand('Copy');
                } else if (window.getSelection) {
                    // other browsers
                    const selection = window.getSelection();
                    const range = document.createRange();
                    range.selectNodeContents(promoCode);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand('Copy');
                }
            }
        }
    }

    stickyCheck() {
        if (typeof this.headWrapper !== 'undefined') {
            document.addEventListener('scroll', this.setSticky)
            document.addEventListener('onload', this.setSticky)
        }
    }

    setSticky() {
        const offsetTop = document.documentElement.scrollTop;
        if (offsetTop > 20) {
            this.body?.classList.add('sticky');
        }
        if (offsetTop < 21) {
            this.body?.classList.remove('sticky');
            this.headWrapper?.classList.remove('_active');
        }
    }

    removeStickyCheck() {
        document.removeEventListener('scroll', this.setSticky);
        document.removeEventListener('onload', this.setSticky);
    }

    showHideMobileSearchBarCategoryProductAndSearchHistory() {
        const searchBar = document.querySelector('.head-search-wrapper-c') as HTMLElement;
        const maxTabletLandscapeWidth: number = 1279;
        const maxTabletLandscapeWidthQuery = window.matchMedia(`(max-width: ${maxTabletLandscapeWidth}px)`);
        const suggestionBackground: HTMLElement = document.querySelector('.suggestion-background');


        if (maxTabletLandscapeWidthQuery.matches) {
            const searchBarWrapper = document.querySelector<HTMLDivElement>(".head-wrapper.newnav")
            const originalWrapperHeight = searchBarWrapper?.style.height
            const st = window.pageYOffset || document.documentElement.scrollTop;

            if (st > 20) {
                // downscroll code
                searchBar.style.opacity = '0';
                searchBar.style.pointerEvents = 'none';
                suggestionBackground.style.opacity = '0'

                if (searchBarWrapper) {
                    searchBarWrapper.style.height = originalWrapperHeight
                }
            } else {
                // upscroll code
                searchBar.style.opacity = '1';
                searchBar.style.pointerEvents = 'auto';
                suggestionBackground.style.opacity = '1'
            }
        }
    }

    removeShowHideMobileSearchBarCategoryProductAndSearchHistory() {
        document.removeEventListener('scroll', this.showHideMobileSearchBarCategoryProductAndSearchHistory);
    }

    mobileSearchBarCategoryProductAndSearchHistory(searchBar: HTMLElement) {
        const searchBarWrapper = document.querySelector<HTMLDivElement>(".head-wrapper.newnav")
        if (searchBarWrapper && searchBar) {
            document.addEventListener('scroll', this.showHideMobileSearchBarCategoryProductAndSearchHistory);
        }
    }

    searchSuggestCategoryProductAndSearchHistory(searchBar: HTMLElement) {
        const suggest = (query: string) => {
            if (query) {
                window.fetch('/product/search/suggest?layout=v2&query=' + query)
                    .then(data => data.text())
                    .then((html) => {

                        if (html && html.length > 203) {
                            this.suggestContainer.innerHTML = html;
                            fadeIn(this.suggestContainer, 'flex');

                            const reminderWrapper = this.suggestContainer.querySelector('.basket-reminder-wrapper') as HTMLElement;
                            const reminderBtn = this.suggestContainer.querySelector('.add-to-reminder') as HTMLButtonElement;
                            const articleId = this.suggestContainer.querySelector('.js-suggest-article-product-id')?.getAttribute('data-article-id')

                            if (articleId && reminderWrapper && reminderBtn && !reminderWrapper.classList.contains('hidden')) {
                                getReminder(reminderBtn, articleId);

                                reminderBtn.addEventListener('click', () => {
                                    if (reminderBtn.classList.contains('setReminder')) {
                                        setReminder(reminderBtn, articleId, 'toast');
                                    }
                                    if (reminderBtn.classList.contains('removeReminder')) {
                                        removeReminder(reminderBtn, articleId, 'toast');
                                    }
                                })
                            }

                            this.suggestContainer.classList.add('active');

                            showHighlights(query);

                            const showAll = document.querySelector('.toggle-header-search');
                            showAll && showAll.addEventListener('click', (event) => {
                                event.preventDefault();
                                (document.getElementById('form-header-search') as HTMLFormElement).submit();
                            });
                            updateDirectOrderPrice();

                            this.defaultSuggestContainer.style.display = 'none';
                        } else {
                            this.suggestContainer.style.display = 'none';
                            this.defaultSuggestContainer.style.display = 'flex';
                            this.suggestContainer.classList.remove('active');
                        }

                    });
            }
        }


        // search
        let searchUsed = false;
        const mobileSearchBackWrapper: HTMLDivElement = document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper')
        const mobileSearchBackBtn: HTMLDivElement = document.querySelector('.head-search-wrapper-c__head-search-form__back-wrapper__back-btn i')

        const headerSearchQuery = document.getElementById('header-search-query') as HTMLInputElement;

        headerSearchQuery && headerSearchQuery.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                const queryString = headerSearchQuery.value;
                if (queryString.length === 0 && window.innerWidth > this.desktopWidth && this.defaultSuggestContainer) {
                    e.preventDefault();
                    this.suggestContainer.style.display = 'none';
                    this.defaultSuggestContainer.style.display = 'flex';
                } else {
                    this.defaultSuggestContainer.style.display = 'none';
                }
            }
        });

        headerSearchQuery && headerSearchQuery.addEventListener('input', () => {

            suggest(headerSearchQuery.value);
            if (!searchUsed) {
                document.addEventListener('keydown', (event) => {
                    if (event.key === 'Escape') {
                        this.closeSuggestCategoryProductAndSearchHistory(searchBar);
                    }
                });
                searchUsed = true;
            }

        });

        const handleInputFocus = () => {

            this.removeStickyCheck();
            this.removeShowHideMobileSearchBarCategoryProductAndSearchHistory();
            const queryString = headerSearchQuery.value;
            this.suggestionBackground.style.display = 'flex';

            setTimeout(() => {
                this.suggestionBackground.style.opacity = '1';
            }, 10);
            if (queryString.length) {
                this.defaultSuggestContainer.style.display = 'none';

                if (this.suggestContainer) {
                    fadeIn(this.suggestContainer, 'flex')
                }
            } else if (queryString.length === 0 && this.defaultSuggestContainer) {
                this.defaultSuggestContainer.style.display = 'flex';

                if (this.suggestContainer) {
                    this.suggestContainer.style.display = "none"
                }
            }

            if (mobileSearchBackWrapper && window.innerWidth < this.tabletWidth) {
                mobileSearchBackWrapper.style.display = 'flex';
                mobileSearchBackWrapper.style.width = '32px';
                mobileSearchBackBtn.style.fontSize = '1.5rem';
            }
        }

        headerSearchQuery && headerSearchQuery.addEventListener('click', handleInputFocus);
        headerSearchQuery && headerSearchQuery.addEventListener('focus', handleInputFocus);

        // Needs to listen on document, because target element is not available on mount time
        fromEvent(document, 'mousemove')
            .pipe(
                tap((event: MouseEvent) => {
                    const closeOnLeave = (element: HTMLElement, closeCallback: () => void) => {
                        const rect = element.getBoundingClientRect()

                        if (event.clientY > rect.top) {
                            if (event.clientX < rect.left || event.clientX > rect.right || event.clientY > rect.bottom) {
                                closeCallback()
                            }
                        }
                    }

                    if (this.defaultSuggestContainer?.style.display === "flex") {
                        closeOnLeave(
                            this.defaultSuggestContainer,
                            () => this.closeDefaultSuggest()
                        )
                    }

                    const suggestElement = this.suggestContainer?.querySelector<HTMLDivElement>(".suggestion.v2")

                    if (suggestElement && this.suggestContainer?.style.display === "flex") {
                        closeOnLeave(
                            suggestElement,
                            () => this.closeSuggestCategoryProductAndSearchHistory(searchBar)
                        )
                    }
                })
            ).subscribe();

        fromEvent(document, 'click')
            .pipe(
                tap((event: MouseEvent) => {
                    const inputRect = document.getElementById("form-header-search")?.getBoundingClientRect()

                    const closeOnClickAway = (element: HTMLElement, closeCallback: () => void) => {
                        const elementRect = element.getBoundingClientRect()

                        if (inputRect) {
                            if (event.clientY <= inputRect.bottom) {
                                if (event.clientX < inputRect.left || event.clientX > inputRect.right || event.clientY < inputRect.top) {
                                    closeCallback()
                                }
                            } else {
                                if (event.clientX < elementRect.left || event.clientX > elementRect.right || event.clientY > elementRect.bottom) {
                                    closeCallback()
                                }
                            }
                        }
                    }

                    if (this.defaultSuggestContainer?.style.display === "flex") {
                        closeOnClickAway(
                            this.defaultSuggestContainer,
                            () => this.closeDefaultSuggest()
                        )
                    }

                    const suggestElement = this.suggestContainer?.querySelector<HTMLDivElement>(".suggestion.v2")

                    if (suggestElement && this.suggestContainer?.style.display === "flex") {
                        closeOnClickAway(
                            suggestElement,
                            () => this.closeSuggestCategoryProductAndSearchHistory(searchBar)
                        )
                    }
                })
            ).subscribe();

        this.suggestionBackground && this.suggestionBackground.addEventListener('click', () => this.closeSuggestCategoryProductAndSearchHistory(searchBar));

        const showHighlights = (query: string) => {
            const suggestionKey = document.querySelectorAll('.suggestion-key')[0];
            if (typeof suggestionKey !== 'undefined') {
                const suggestionKeyUl = suggestionKey.querySelector('ul');
                const suggestionKeyUlLi = suggestionKeyUl && suggestionKeyUl.querySelectorAll('li');


                suggestionKeyUlLi?.forEach((li) => {
                    const linkText = li.querySelector('a');
                    highlight(linkText, query);
                });
            }
        }

        const highlight = (inputText: HTMLElement, text: string) => {
            let innerHTML = inputText.innerHTML;
            const index = innerHTML.toLowerCase().indexOf(text.toLowerCase());
            if (index >= 0) {
                innerHTML = innerHTML.substring(0, index) + '<span>' + innerHTML.substring(index, index + text.length) + '</span>' + innerHTML.substring(index + text.length);
                inputText.innerHTML = innerHTML;
            }
        }

        const updateDirectOrderPrice = () => {
            const input = this.suggestContainer.querySelector('.search-product-order-qty') as HTMLInputElement;
            if (!input) return;
            input.addEventListener('change', () => {
                updatePrice(this.suggestContainer.querySelector('.js-suggest-article-product-id'), input.value);
            });

            const updatePrice = (el: HTMLElement, count: string) => {
                const articleId = el.dataset.articleId;
                const priceContainer = el.querySelector('.price-detail-offer');
                const noScaledPrice = this.suggestContainer.querySelector('.no-scaled-price');
                if (noScaledPrice) return;
                window.fetch(param.get('urlPrefix') + '/pricing/article/' + articleId + '?quantity=' + count +
                    '&profile=' + param.get('profile') + '&language=' + param.get('clientLocale') + '&legalCountry=' +
                    param.get('legalCountry') + '&deliveryCountry=' + param.get('deliveryCountry') + '&identitySub=' +
                    param.get('identitySub') + '&tenant=' + param.get('tenant') + '&username=' +
                    param.get('identUsername') + '&isGuest=' + param.get('getIdentIsGuest') + '&design=variant')
                    .then((res) => {
                        return res.text()
                    })
                    .then(text => {
                        const html = document.createRange().createContextualFragment(text);
                        const price = html.querySelector('.variant-price');
                        price && priceContainer && (priceContainer.innerHTML = price.textContent);
                    })
                return false;
            }
        }

        this.deleteUserHistorySuggestCategoryProductAndSearchhistory();
    }

    deleteUserHistorySuggestCategoryProductAndSearchhistory() {
        const userHistoryItems: NodeListOf<HTMLDivElement> = document.querySelectorAll('.default-suggestion-key__search-user-history-delete-item');

        userHistoryItems.forEach(item => {
            item?.addEventListener('click', e => {
                fetch(item.dataset?.action, {method: 'POST'})
                    .then((response: Response) => {
                        if (response.ok) {
                            fetch('/product/search/user-history/logged-in-user',
                            {method: 'GET', cache: 'no-cache'})
                                .then((response: Response) => {
                                    if (response.ok) {
                                        return response.text();
                                    }
                                })
                                .then((html) => {
                                    this.defaultSuggestContainer.innerHTML = html;
                                    this.deleteUserHistorySuggestCategoryProductAndSearchhistory();
                                });
                        }
                    })
            })
        })
    }

    profileToggle() {
        const headProfile = document.querySelectorAll('.head-profile');
        const onClickOutside = (e: Event) => {
            if (!(e.target as HTMLElement).parentElement.className.includes('head-profile')) {
                headProfile.forEach((profile => profile.classList.remove('active')));
                window.removeEventListener('click', onClickOutside);
            }
        };

        headProfile.forEach((profile) => {
            profile.addEventListener('click', () => {
                profile.classList.toggle('active');
                const tooltip = profile.querySelector('.tooltipbox');
                tooltip && tooltip.classList.toggle('hidden');
                if (profile.classList.contains('active')) {
                    window.addEventListener('click', onClickOutside);
                }
            });
        });
    }

    protected closeAllFlyouts(headToolElements: NodeListOf<HTMLDivElement>) {

        if (!headToolElements) return;

        headToolElements.forEach((headToolElement) => {

            const flyout = headToolElement.querySelector('.head-tool-flyout');

            if (!flyout) return;

            flyout.removeAttribute('style');
        });
    }

    protected loginButton() {

        const myshopButtonElement = document.querySelector('.head-tool.head-myshop');
        const myshopLabelElement = myshopButtonElement?.querySelector('.indicator-wrapper');
        const loginFlyoutElement = myshopButtonElement?.querySelector('.flyout-login');
        const myshopFlyoutElement = myshopButtonElement?.querySelector('.flyout-myshop');

        if (!myshopButtonElement || !loginFlyoutElement || !myshopFlyoutElement) return;

        // if the loginFlyout exists (in case of a/b test) we hide the myshopFlyout first
        myshopFlyoutElement.classList.add('hidden');

        const isLoginFlyoutDismissed = JSON.parse(sessionStorage.getItem('login-flyout-shown'));
        if (!isLoginFlyoutDismissed) {

            // then we show the loginFlyout initially
            loginFlyoutElement.setAttribute('style', 'visibility: visible; opacity: 1;');
            sessionStorage.setItem('login-flyout-shown', 'true')
        }


        // init timer to hide login flyout from DOM
        const hideLoginFlyoutTimer = timer(1000 * 60) // 60 seconds
            .pipe(
                tap(() => loginFlyoutElement.classList.add('hidden')),
            ).subscribe();

        // show normal myshop flyout on menu hover
        fromEvent(myshopLabelElement, 'mouseover')
            .pipe(
                tap(event => {

                    loginFlyoutElement.classList.add('hidden');
                    myshopFlyoutElement.classList.remove('hidden');
                })
            )
            .subscribe();

        // cancel subscription for the timer to hide login flyout from DOM
        fromEvent(loginFlyoutElement, 'mouseover')
            .pipe(
                tap(event => hideLoginFlyoutTimer.unsubscribe()),
            )
            .subscribe();

        fromEvent(document, 'click')
            .pipe(
                tap((event: MouseEvent) => {

                    const cookieOverlay = document.querySelector('#modal-cookie-background');
                    const loginFlyoutElementDimensions = loginFlyoutElement.getBoundingClientRect();

                    if (cookieOverlay && loginFlyoutElementDimensions) {
                        const cookieOverlayDimensions = cookieOverlay.getBoundingClientRect();

                        const clickIsOutsideFromCookieOverlay = (event.clientX < cookieOverlayDimensions.left ||
                            event.clientX > cookieOverlayDimensions.right ||
                            event.clientY < cookieOverlayDimensions.top ||
                            event.clientY > cookieOverlayDimensions.bottom);

                        const clickIsOutsideFromLoginFlyout = (event.clientX < loginFlyoutElementDimensions.left ||
                            event.clientX > loginFlyoutElementDimensions.right ||
                            event.clientY < loginFlyoutElementDimensions.top ||
                            event.clientY > loginFlyoutElementDimensions.bottom);

                        if (clickIsOutsideFromCookieOverlay && clickIsOutsideFromLoginFlyout) loginFlyoutElement.classList.add('hidden');
                    }
                })
            ).subscribe();
    }

    init() {

        const searchBar = document.querySelector('.head-search-wrapper-c') as HTMLElement;
        this.searchSuggestCategoryProductAndSearchHistory(searchBar);
        this.searchResetButtonProductCategoryHistory(searchBar);
        this.mobileSearchBarCategoryProductAndSearchHistory(searchBar);

        if (!this.minDesktopWidthQuery.matches) {
            this.stickyCheck();
        }

        this.toggleExtendedView();
        this.productSearch();
        this.closeFlyout();
        this.toggleFlyout();
        this.preventDefaultCart();
        this.topBar();
        this.profileToggle();
        this.loginButton()
    }
}
