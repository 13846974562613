export class errorLog {

  urlPrefix = document.querySelector('input[name="urlprefix"]') as HTMLInputElement;
  failedLogging = 0;

  initializeLogging() {
    window.onerror = (message, source, line, col, error) => {
      const locale = document.querySelector('input[name="clientLocale"]') as HTMLInputElement;
      const tenant =  document.querySelector('input[name="tenant"]') as HTMLInputElement;

      let stack = '';
      if (typeof error !== 'undefined') {
        if (typeof error.stack !== 'undefined') {
          stack = error.stack;
        }
      }

      const log = {
        message: message,
        source: source,
        line: line,
        col: col,
        stack: stack,
        logLevel: 'global',
        vertical: '',
        clientLocale: locale && locale.value,
        tenant: tenant && tenant.value,
      };

      this.logError(log);
    };
  }

  logError(log: Object) {
    if (this.failedLogging < 5) {
      window.fetch(this.urlPrefix && this.urlPrefix.value + '/log/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(log),
        })
        .then((res) => {
          if (!res.ok) {
            this.failedLogging++;
          }
          return res.text()
        })
        .then(data => {
          this.failedLogging++;
        });
    }
  }

  init() {
    this.initializeLogging();
  }
}
