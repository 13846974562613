import { querySelectValue } from './helpers';

export function serializeCheckoutForm(form: HTMLFormElement) {
  const mainAddress: any = createAddress(form, "mainAddress");

  let customerProfile = (<HTMLInputElement>form.querySelector("[name=customerProfile]:checked")).value;
  if (!customerProfile) {
    customerProfile = "B2B";
  }

  let isGuest = 1;
  if(form.querySelector("[name='login.enabled']")) {
    isGuest = (<HTMLInputElement>form.querySelector("[name='login.enabled']")).checked ? 0 : 1;
  }
  const isUpdateMode = false;
  const countryCode = querySelectValue(form, "[name=countryCode]");
  const subscribeNewsletter = (querySelectValue(form, "[name=subscribeNewsletter]:checked") != null);

  const customer = {
    customerType: customerProfile,
    customerNumber: querySelectValue(form, "[name='organisation.customerNumber']"),
    name: querySelectValue(form, "[name='organisation.name']"),
    name2: querySelectValue(form, "[name='organisation.name2']"),
    nameAdditional: querySelectValue(form, "[name='organisation.nameAdditional']"),
    industrySsiCode: querySelectValue(form, "[name='organisation.industrySsiCode']"),
    industryNaceCode: querySelectValue(form, "[name='organisation.industryNaceCode']"),
    industryName: querySelectValue(form, "[name='organisation.industryName']"),
    vatId: querySelectValue(form, "[name='organisation.vatId']"),
    countryCode: mainAddress.countryCode
  };

  const identity: any = {
    customerVerified: querySelectValue(form, "[name='customerVerified']"),
    customerType: customerProfile,
    title: querySelectValue(form, "[name='identity.title']:checked"),
    givenName: querySelectValue(form, "[name='identity.givenName']"),
    familyName: querySelectValue(form, "[name='identity.familyName']"),
    password: isGuest === 1 ? null : querySelectValue(form, "[name='identity.password']"),
    passwordConfirmation: isGuest === 1 ? null : querySelectValue(form, "[name='identity.passwordConfirmation']"),
    birthday: querySelectValue(form, "[name='identity.birthday']"),
    phone: querySelectValue(form, "[name='identity.phone']"),
    fax: querySelectValue(form, "[name='identity.fax']"),
    countryCode: mainAddress.countryCode,
    // guest: form.querySelector("[name='login.enabled']").checked ? 0 : 1
  };


  if ((<HTMLInputElement>form.querySelector("[name='identity.terms']")).checked) {
    identity.terms = 1;
  }

  const login = {
    email: querySelectValue(form, "[name='login.email']")
  };

  const newsletterSubscription = {
    email: login.email,
    subscribeNewsletter: querySelectValue(form, "[name='newsletterSubscription.subscribeNewsletter']:checked")
  };

  mainAddress.customerProfile = customerProfile;
  mainAddress.delivery = true;
  mainAddress.billing = true;
  mainAddress.primaryDelivery = true;
  mainAddress.primaryBilling = true;
  mainAddress.main = true;
  mainAddress.email = login.email;
  mainAddress.fax = identity.fax;
  if (mainAddress.customerProfile === "B2B") {
    mainAddress.name = customer.name;
    mainAddress.name2 = customer.name2;
  }
  mainAddress.nameAdditional = customer.nameAdditional;
  mainAddress.title = identity.title;
  mainAddress.givenName = identity.givenName;
  mainAddress.familyName = identity.familyName;

  const addressList = [mainAddress];
  const deliveryAddressEnabled = form.querySelector("[name='deliveryAddress.enabled']");
  if (deliveryAddressEnabled && (<HTMLInputElement>deliveryAddressEnabled).checked) {
    const deliveryAddress: any = createAddress(form, "deliveryAddress");
    deliveryAddress.customerProfile = customer.customerType;
    deliveryAddress.delivery = true;
    addressList.push(deliveryAddress);
    const deliveryAddressPrimary: any = form.querySelector("[name='deliveryAddress.primary']");
    if (deliveryAddressPrimary && deliveryAddressPrimary.checked) {
      deliveryAddress.primaryDelivery = true;
      mainAddress.primaryDelivery = false;
    }
  }

  const billingAddressEnabled = form.querySelector("[name='billingAddress.enabled']");
  if (billingAddressEnabled && (<HTMLInputElement>billingAddressEnabled).checked) {
    const billingAddress: any = createAddress(form, "billingAddress");
    billingAddress.customerProfile = customer.customerType;
    billingAddress.billing = true;
    addressList.push(billingAddress);
    const billingAddressPrimary: any = form.querySelector("[name='billingAddress.primary']");
    if (billingAddressPrimary && (<HTMLInputElement>billingAddressPrimary).checked) {
      billingAddress.primaryBilling = true;
      mainAddress.primaryBilling = false;
    }
  }

  const payerAddressEnabled = form.querySelector("[name='payerAddress.enabled']");
  if (payerAddressEnabled && (<HTMLInputElement>payerAddressEnabled).checked) {
    const payerAddress: any = createAddress(form, "payerAddress");
    payerAddress.customerProfile = customer.customerType;
    payerAddress.payer = true;
    addressList.push(payerAddress);
    const payerAddressPrimary: any = form.querySelector("[name='payerAddress.primary']");
    if (payerAddressPrimary && (<HTMLInputElement>payerAddressPrimary).checked) {
      payerAddress.primaryPayer = true;
      mainAddress.primaryPayer = false;
    }
  }

  const hasDiffDeliveryAdrChecked = form.querySelector('input[name="deliveryAddress.enabled"]');
  const hasDiffBillingAdr = form.querySelector('input[name="billingAddress.enabled"]');
  const hasDiffPayerAdr = form.querySelector('input[name="payerAddress.enabled"]');

  return serialize({
    addressList: addressList,
    organisation: customer,
    identity: identity,
    login: querySelectValue(form, 'input[name="isUpdateMode"]') == "false" ? login : null,
    newsletterSubscription: newsletterSubscription,
    isCheckout: querySelectValue(form, 'input[name="isCheckout"]'),
    hasDiffDeliveryAdr: hasDiffDeliveryAdrChecked ? (<HTMLInputElement>hasDiffDeliveryAdrChecked).checked : false,
    hasDiffBillingAdr: hasDiffBillingAdr ? (<HTMLInputElement>hasDiffBillingAdr).checked : false,
    hasDiffPayerAdr: hasDiffPayerAdr ? (<HTMLInputElement>hasDiffPayerAdr).checked : false,
    updateMode: querySelectValue(form, 'input[name="isUpdateMode"]'),
    customerProfile: customerProfile,
    guest: isGuest,
    isUpdateMode: isUpdateMode,
    countryCode: countryCode,
    subscribeNewsletter: subscribeNewsletter
  });
}

function createAddress(form: any, name: any) {
  return {
    addressSourceId: querySelectValue(form, "[name='" + name + ".addressSourceId']"),
    name: querySelectValue(form, "[name='" + name + ".name']"),
    name2: querySelectValue(form, "[name='" + name + ".name2']"),
    nameAdditional: querySelectValue(form, "[name='" + name + ".nameAdditional']"),
    vatId: querySelectValue(form, "[name='" + name + ".vatId']"),

    title: querySelectValue(form, "[name='" + name + ".title']:checked"),
    givenName: querySelectValue(form, "[name='" + name + ".givenName']"),
    familyName: querySelectValue(form, "[name='" + name + ".familyName']"),

    street: querySelectValue(form, "[name='" + name + ".street']"),
    postalCode: querySelectValue(form, "[name='" + name + ".postalCode']"),
    city: querySelectValue(form, "[name='" + name + ".city']"),
    countryCode: querySelectValue(form, "[name='" + name + ".countryCode']"),

    email: querySelectValue(form, "[name='" + name + ".email']"),
    phone: querySelectValue(form, "[name='" + name + ".phone']"),
    fax: querySelectValue(form, "[name='" + name + ".fax']"),
    publicId: querySelectValue(form, "[name='" + name + ".publicId']")
  };
}

function getParams(value?: any, path?: string, params?: any) {
  params = params || [];
  var type = typeof value;
  if (type === "string" || type === "number" || value instanceof String || value instanceof Number) {
    var param: any = {};
    param[path] = value;
    params.push(param);
  } else if (type === "boolean" || value instanceof Boolean) {
    var param: any = {};
    param[path] = value ? "1" : "0";
    params.push(param);
  } else if (Array.isArray(value)) {
    for (var i = 0; i < value.length; i++) {
      getParams(value[i], path + "[" + i + "]", params);
    }
  } else if (value === null || value === undefined) {
    return;
  } else {
    var prefix = path ? path + "." : "";
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        getParams(value[key], prefix + key, params);
      }
    }
  }

  return params;
}

export function serialize(target: any) {
  return getParams(target)
    .map((kv: any) => {
      let data;
      for (const [key, value] of Object.entries(kv)) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(value.toString());
      }
    })
    .join("&");
}

export function formSerialize(form: HTMLFormElement) {
  let field, l, s = [];
  if (typeof form === 'object' && form.nodeName === 'FORM') {
    const len = form.elements.length;
    for (let i = 0; i < len; i++) {
      field = form.elements[i] as HTMLFormElement;
      if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
        if (field.type === 'select-multiple') {
          l = (form.elements[i] as HTMLFormElement).options.length;
          for (let j = 0; j < l; j++) {
            if (field.options[j].selected)
              s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[j].value);
          }
        } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
          s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value);
        }
      }
    }
  }
  return s.join('&').replace(/%20/g, '+');
}

