import 'core-js';
import '@hooked74/events-polyfill';
import 'whatwg-fetch'
import 'formdata-polyfill';
import 'url-search-params-polyfill';
import 'polyfill-nodelist-foreach';
import { headNav } from './component/layout/head-nav';
import { headTools } from './component/layout/head-tools';
import { modals } from './component/layout/modals';
import { Slider } from './component/global/splide';
import { Widgets } from './component/global/widgets';
import { Energylabel } from './component/global/energylabel';
import { errorLog } from './component/global/error-log';
import { Authentication } from './component/global/auth';
import { SmartSupply } from "./component/myshop/smart-supply";

let hNav = new headNav();
let hTools = new headTools();
let mdls = new modals();
let slider = new Slider();
let widgets = new Widgets();
let energylabel = new Energylabel();
let error = new errorLog();
let auth = new Authentication();

hNav.init();
hTools.init();
mdls.init();
slider.init();
widgets.init();
energylabel.init();
error.init();
auth.init();

new SmartSupply();
